'use client';

export default function GlobalError() {
  return (
    <html>
      <body>
        <h1>500</h1>
        <h2>Internal Error</h2>
      </body>
    </html>
  );
}